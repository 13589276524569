.icon {
  transform: rotate(210deg);
  transform-origin: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.uk-button-primary {
  background: rgb(1, 110, 101);
  background: linear-gradient(
    142deg,
    rgba(1, 110, 101, 1) 0%,
    rgba(1, 110, 101, 1) 57%,
    rgba(0, 188, 104, 1) 100%
  );
  text-transform: uppercase;
  border-radius: 0px; /* 8px */
}

.nav-logo {
  width: 36px;
}

.nav-bg {
  background: rgb(1, 110, 101);
}

.foot-bg {
  background: rgb(1, 110, 101);
}

.feature-1 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/feat-2.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/feat-2.jpg");
  background-position: center;
  background-size: cover;
  background-color: #1e87f0;
}

.feature-2 {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/feat-1.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/feat-1.jpg");
  background-position: center;
  background-size: cover;
  background-color: #666;
}

#fullscreen-map {
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
  background: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
  outline: none;
}

#map-full {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1000;
  display: block;
}

/* Scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(1, 110, 101);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #000;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(1, 110, 101);
  border-radius: 0px;
  border: 0;
}

.box-shadow-large {
  box-shadow: -40px 40px 160px 0 rgb(0 0 0 / 8%),
    -8px 8px 15px 0 rgb(120 120 120 / 4%), 3px 3px 30px 0 rgb(0 0 0 / 4%);
}

.profile-nav {
  width: 45px;
  height: 45px;
  -webkit-transition: width 200ms, height 200ms; /* Safari */
  transition: width 200ms, height 200ms;
  box-shadow: -40px 40px 160px 0 rgb(0 0 0 / 28%),
    -8px 8px 15px 0 rgb(120 120 120 / 25%), 3px 3px 30px 0 rgb(0 0 0 / 25%);
  border: 2px solid #1e87f0;
}

.profile-nav:hover {
  width: 47px;
  height: 47px;
  box-shadow: -40px 40px 160px 0 rgb(0 0 0 / 32%),
    -8px 8px 15px 0 rgb(120 120 120 / 32%), 3px 3px 30px 0 rgb(0 0 0 / 32%);
}

.uk-input {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
  border: 0;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
  border: 0;
}

.uk-textarea {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
  font-size: 16px;
  border: 0;
}

.uk-card-body {
  background-color: #f0f1f3;
  border-radius: 0px; /* 8px */
}

.hulk {
  color: #016e65;
  font-weight: bold;
}

.login-section {
  min-height: 100vh;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/hero.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("./images/hero.jpg");
  background-size: cover;
  /* background: url("https://live.staticflickr.com/65535/50685960898_b00acf251b_k.jpg"); */
  background-color: rgb(1, 110, 101);
}

.uk-input-large,
.uk-select-large {
  height: 54px !important;
}

.crew-logo {
  width: 50px;
  margin-right: 10px;
}

.reset-password-link {
  color: #555;
}

.uk-table-hover tbody tr:hover,
.uk-table-hover > tr:hover {
  background: rgba(0, 0, 0, 0.02);
}

.flights-stats .uk-text-bold {
  font-size: 48px;
}

.uk-nav > li > a.uk-active {
  color: #000;
}

.uk-navbar-nav > li > a.uk-active {
  color: #fff;
}

.nav-ribbon {
  background: linear-gradient(
    142deg,
    rgba(3, 102, 180, 1) 30%,
    rgba(2, 131, 231) 100%
  );
  color: #fff;
  padding: 0.25rem;
}

.nav-ribbon .uk-container {
  display: flex;
}

.nav-ribbon-info {
  justify-self: flex-end;
  text-align: right;
  letter-spacing: 0.04rem;
}

.nav-ribbon-info span {
  margin-left: 0.5rem;
}

.searchWrapper {
  height: inherit;
  overflow-y: scroll;
  border: none !important;
}
/* 
html {
  filter: invert(1) hue-rotate(180deg);
}

html img{
  filter: invert(1) hue-rotate(180deg);
} */

/* Dark Mode !!! */

/* html[theme='dark-mode'] {
  filter: invert(90%) contrast(1.1) saturate(1.05) hue-rotate(180deg);
}

html[theme='dark-mode'] .uk-button-primary {
  filter: invert(90%) contrast(1.1) saturate(1.05) hue-rotate(180deg);
}

html[theme='dark-mode'] img,
html[theme='dark-mode'] .bg-image,
html[theme='dark-mode'] .feature-1,
html[theme='dark-mode'] .feature-2,
html[theme='dark-mode'] .section-intro {
  filter: invert(1) hue-rotate(180deg);
}

html[theme='dark-mode'] #stats-total-flights {
  filter: invert(1) hue-rotate(180deg);
  color: white;
}


html[theme='dark-mode'] .uk-logo,
html[theme='dark-mode'] .profile-nav,
html[theme='dark-mode'] .login-section,
html[theme='dark-mode'] .section-intro .uk-button-primary
html[theme='dark-mode'] .uk-navbar-dropdown,
html[theme='dark-mode'] .foot-bg {
  filter: invert(1) hue-rotate(180deg);
} */
