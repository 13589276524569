.section-intro {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../../images/hero.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../../images/hero.jpg");
  background-size: cover;
  /* background: url("https://live.staticflickr.com/65535/50685960898_b00acf251b_k.jpg"); */
  background-color: rgb(1, 110, 101);
}

@media (max-width: 960px) {
.head-map {
    height: 201.333px !important;
}
}

.full-map-container {
position: absolute;
width: 100%;
// height: calc(100% - 160px);
top: 80px;
right: 0;
bottom: 80px;
left: 0;
z-index: 500;
}